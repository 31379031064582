const projectsList = [
  {
    name: "Waxd",
    period: "Nov 2021 - Mar 2022, Oct 2022 - Current",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Development of mobile application with React Native, Development of application with .Net Maui for Mobile POS Systems",
    teamSize: "3",
    about:
      "Waxd is a company with a specialized POS (Point of Sale) payment ecosystem who required a mobile application where users can view and add wallets, transaction history and add cards linked to specific wallets. They also required an application for a portable POS android device where Taxi owners can setup the device for drivers so commuters can tap with a card and make payments for trips, track daily statistics like total commuters, fare collected, taxi trips, etc.",
    responsibilities: [
      "Building frontend for mobile application (Yarona) with registration and login functionality",
      "Integration with API's for user registration, login, viewing transactions, adding multiple wallets and adding funds",
      "Uploading of App to Google Play store",
      "Building frontend for POS system app using .Net Maui",
      "Integration with API to retrieve user data and display in frontend",
    ],
    tools: ["ReactJS", "NodeJS", "CSS", ".Net Maui", "C#"],
    urlName: "Yarona App",
    url: "https://play.google.com/store/apps/details?id=com.typedev.yarona",
  },
  {
    name: "Card Curator",
    period: "2020 - 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website Development",
    teamSize: "4-6",
    about:
      "Card Curator is an American Company designed to optimize how you use your credit cards to get the maximum amount of rewards for using them.",
    responsibilities: [
      "Frontend Development of the Website",
      "Responsive Styling for Mobile",
      "Semi-integration with the Backend for a Profile page",
      "SEO Optimization of the Site for a React Application",
    ],
    tools: ["ReactJS", "After Effects", "CSS"],
  },
  {
    name: "NanoTime",
    period: "2020 - 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website Development",
    teamSize: "2",
    about:
      "NanoTime is cycling club that promotes an active and balanced lifestyle, encouraging people to get up and start moving to live a healthier life.",
    responsibilities: [
      "Frontend Development of the Website",
      "Responsive Styling for Mobile",
      "Integration with API for contanct us and joining club email",
      "Image Optimization",
      "SEO Optimization",
    ],
    tools: ["ReactJS", "NodeJS", "CSS"],
    urlName: "NanoTime Website",
    url: "https://nanotime.co.za",
  },
  {
    name: "Mondo",
    period: "Jul 2022 - Aug 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website Development & Component Creation",
    teamSize: "8-12",
    about:
      "Mondo is a company that specialises in Cellphone and Mobile contract deals, helping users across SA find the best deals.",
    responsibilities: [
      "Frontend Development of the Website",
      "Component Development",
      "Responsive Styling for Mobile",
      "Integration with API for retrieval of deals",
    ],
    tools: ["ReactJS", "CSS"],
    urlName: "Mondo Website",
    url: "https://www.mondo.co.za",
  },
  {
    name: "NEC XON",
    period: "Oct 2021 - Nov 2021, Jan 2022 - Feb 2022, May 2022 - Jul 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Development of POC's with ReactJS Framework for Khiosk POS systems",
    teamSize: "3",
    about:
      "NEC XON provides deep integration skills and experience with global technology leadership into a world-class service for customers. They required three POC (Proof of Concept) applications for Three different clients (Telkom, CellC and SA Home Affairs respectively) that would work on POS (Point of Sale) kiosks, where users can access specific services.",
    responsibilities: [
      "Build Frontend for Three separate POC (Proof of Concept) applications that will be used on POS (Point of Sale) kiosks",
      "Integration with API for both Hardware (Cameras, ID & Barcode scanners, etc) and retrieval of information",
      "Integration of Mapbox for address retrieval and display",
      "Building of fail screens and ensuring the API is active when a user uses the application",
    ],
    tools: ["ReactJS", "CSS", "NodeJS", "Mapbox"],
  },
  {
    name: "Elepay",
    period: "Apr 2022 - Jun 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Development of Website",
    teamSize: "3",
    about:
      "Elepay offers loans to Australian property owners for buying, renovations and upgrading. They required help with frontend development and updating their current website, as well as creating of new interactive and dynamic services pages.",
    responsibilities: [
      "Build new services pages and updating current pages to new designs",
      "Integration with API for services and information",
    ],
    tools: ["ReactJS", "CSS", "NodeJS"],
  },
  {
    name: "IoDSA",
    period: "Mar 2022 - Jul 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Development of mobile application using Ionic and React",
    teamSize: "3",
    about:
      "The IoDSA provides its members with a range of services and information that assist governance professionals to operate from an informed, up-to-date and professional perspective. They required an application where members can login to find and book events, find relevant materials, and track points they have acquired.",
    responsibilities: [
      "Update and develop new pages for the frontend of the application",
      "Integrate an API for display of events ",
      "Responsiveness development for mobile screens",
    ],
    tools: ["ReactJS", "Ionic", "CSS", "TypeScript"],
    urlName: "IoDSA Members App",
    url: "https://play.google.com/store/apps/details?id=co.za.iodsa.memberapp",
  },
  {
    name: "Arma Karma",
    period: "Mar 2022 - Apr 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Widget updating",
    teamSize: "2",
    about:
      "Arma Karma is an award-winning insurance subscription, protecting against damage, loss and theft. They required styling and responsive help with a widget that was not rendering properly.",
    responsibilities: [
      "Updating a Widget that had styling issues and caused user frustration",
      "Overriding WordPress Styling which clashed with Widget",
      "Responsive Styling for Mobile",
    ],
    tools: ["CSS", "WordPress"],
    urlName: "Arma Karma widget page",
    url: "https://armakarma.insure/get-started",
  },
  {
    name: "Stats SA",
    period: "Jul 2021 - Jan 2022",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Frontend overhaul",
    teamSize: "2-3",
    about:
      "Statistics South Africa (Stats SA) is the national statistics agency of South Africa established under the Statistics Act (Act No. 6 of 1999) with the aim to produce timely, accurate and accessible official statistics. They required a Frontend overhaul with their current census application that would work on tablets with the twelve official languages of SA.",
    responsibilities: [
      "Frontend overhaul of census app",
      "Responsive Styling for tablet",
      "Styling UI to suit all Twelve languages",
    ],
    tools: ["HTML", "CSS", "JavaScript"],
  },
  {
    name: "AppVicion BlueBase",
    period: "Jan 2021 - Feb 2021, Jul 2021 - Aug 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website Development",
    teamSize: "2-3",
    about:
      "Appvicion was developed with the vision of creating mobile business applications across any industry. We are here to provide the possibilities and clarity for your vision. They required an application where players, coaches and sports doctors can track their own or players injuries and statistics.",
    responsibilities: [
      "Frontend Development of app",
      "Specific Styling for Mobile",
      "Integration with API for login, data retrieval and uploading of data",
    ],
    tools: ["ReactJS", "CSS", "NodeJS"],
  },
  {
    name: "Deep Liquid",
    period: "Apr 2021 - Jun 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Template Development and Clean up",
    teamSize: "1",
    about:
      "They required an application designed to calculate the likelihood of a company being liquidated.",
    responsibilities: [
      "Using the React Template to Create pages that follow their specifications",
      "Ensure that the site is responsive for mobile",
      "Work with Backend resource to ensure Data gets pulled through to Frontend",
    ],
    tools: ["ReactJS", "CSS"],
  },
  {
    name: "Bidvest Data",
    period: "Feb 2021 - Dec 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website and Blog Development",
    teamSize: "3-4",
    about:
      "Bidvest Data is a leading service provider of secure multi-channel communication solutions. They required a website/ blog where users can see the latest they have to offer and other info.",
    responsibilities: [
      "Frontend Development of the Website",
      "Responsive Styling for Mobile",
      "SEO Optimization of the Site for a React Application",
      "Integrated Login, Dashboard, Blog, Current Vacancies elements with Database Capabilities (Working with a Backend Developer)",
      "Use of Framer motion for web animation",
    ],
    tools: ["ReactJS", "Framer Motion", "NodeJS", "CSS"],
  },
  {
    name: "Vhembe Biosphere",
    period: "Apr 2021 - May 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Interactive Mapbox creation and implementation with website",
    teamSize: "1",
    about:
      "Vhembe Biosphere Reserve is a favourite destination for eco-tourism, cultural tourism and related activities. They required a map where users can view and learn about the area.",
    responsibilities: [
      "Research and Create an interactive Map that users can learn about the Vhembe Bio sphere",
      "Usage and integration of Mapbox",
      "Adding Multiple layers that can be shown and hidden by the user",
      "Using .shp and. geojson files to create shapes on the map",
    ],
    tools: ["HTML", "CSS", "Mapbox", "JavaScript", "JQuery"],
    urlName: "Vhembe Biosphere map page",
    url: "https://www.vhembebiosphere.org/interactive-map",
  },
  {
    name: "InfluApp",
    period: "Dec 2020 - Apr 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website Development",
    teamSize: "3",
    about:
      "An application designed to help businesses link with Influencers and give influencers rewards and discounts for using the Application at Registered businesses.",
    responsibilities: [
      "Frontend Development of the Website",
      "Responsive Styling for Mobile",
      "SEO Optimization of the Site for a React Application",
    ],
    tools: ["ReactJS", "CSS", "Framer Motion", "After Effects"],
    urlName: "InfluApp website",
    url: "https://www.weareinfluapp.com/",
  },
  {
    name: "Esportsnow",
    period: "Jun 2020 - Feb 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "WordPress website Development and API Integration",
    teamSize: "3",
    about:
      "An online Esports betting review site that helps users decide where they should go for all their esports betting needs.",
    responsibilities: [
      "Create the website using WordPress",
      "Integrate an API that pulls through live data for esports being played currently",
      "Responsive Styling for Mobile",
      "SEO Optimization of the Site for a React Application",
    ],
    tools: ["ReactJS", "CSS", "WordPress"],
  },
  {
    name: "BoredPass",
    period: "Oct 2020 - May 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Development of Frontend for website",
    teamSize: "2",
    about:
      "BoredPass is your one stop website for adventures, helping you to find the perfect activity based on your location.",
    responsibilities: [
      "Frontend Development of the website",
      "Responsive Styling for Mobile",
      "Styling updates to outdates pages",
    ],
    tools: ["PUG", "CSS"],
    urlName: "BoredPass website",
    url: "https://boredpass.com/",
  },
  {
    name: "Bidvest Data Constellation",
    period: "Jul 2020 - Aug 2020",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Template Development and Frontend overhaul",
    teamSize: "3-4",
    about:
      "Constellation 2.0 allows for easy navigation, retrieval of documents in a seamless manor and quick access to reports on delivery of content. They required a Frontend overhaul for an existing webapp.",
    responsibilities: [
      "Frontend Overhaul",
      "Responsive Styling for Mobile",
      "Updating component layout and styling",
    ],
    tools: ["HTML", "CSS", "JavaScript"],
    urlName: "Bidvest Data Constellation website",
    url: "https://bidvestdata.co.za/web/post/constellation",
  },
  {
    name: "Q4BN",
    period: "Apr 2020 - Jun 2020",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Website Development, Google maps integration",
    teamSize: "2",
    about:
      "Part of Q4 Fuel Company they leverage the collective buying power of their network, to drive down diesel and other service costs for a client's fleet. They required a website where users can see what they have to offer as well an interactive map showing all of their stations.",
    responsibilities: [
      "Frontend Development of the Website",
      "Responsive Styling for Mobile",
      "SEO Optimization of the Site for a React Application",
      "Creation of Google map with interactive quadrants and data",
    ],
    tools: ["ReactJS", "After Effects", "CSS", "Google Maps"],
    urlName: "Q4BN website",
    url: "http://q4bn.co.za/",
  },
  {
    name: "Modulent",
    period: "Apr 2020 - Jul 2021",
    company: "TypeDev",
    jobTitle: "Frontend Developer",
    type: "Frontend upkeep and Updating information",
    teamSize: "2-3",
    about:
      "Modulent is a multi-disciplined Human Capital Management, Organisational Development and Resourcing Company based in Centurion, Pretoria. They required upkeep and updating of their website.",
    responsibilities: ["Frontend Upkeep", "Updating outdated information"],
    tools: ["WordPress", "CSS"],
  },
  {
    name: "Various",
    period: "Jan 2018  - Mar 2020",
    company: "Dynamic Management Systems",
    jobTitle: "Multi-media Designer",
    type: "Various",
    teamSize: "1",
    about:
      "I was the lead Multi-media designer for the designing and building whatever they needed for both web and print.",
    responsibilities: [
      "Development of multiple websites using WordPress",
      "Designing of Brochures, pamphlets, Logo and Company branding",
      "Video management, editing and creation of adverts",
      "Design and development of Learning courses for ISO 9001 certification learning",
      "Design and development of CMS frontend",
      "Support in Learning Software",
      "SEO optimization",
      "Creation of Google ads and optimization search criteria",
    ],
    tools: [
      "WordPress",
      "Adobe Illustrator",
      "Adobe After Effects",
      "Adobe Photoshop",
      "Adobe XD",
      "Adobe Dreamweaver",
      "Adobe InDesign",
      "Google Analytics",
      "ReactJS",
      "JavaScript",
      "HTML",
      "CSS",
      "Outsystems",
    ],
  },
];

export { projectsList };
