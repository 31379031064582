import React, { useEffect, useRef, useState } from "react";
import Xpbar from "../../components/xpbar/xpbar";
import Project from "../../components/project/project";
import Menu from "../../components/menu/menu";

import { Carousel } from "react-responsive-carousel";

import "./_styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import portrait from "../../assets/images/PieterPortrait.png";
import divider from "../../assets/images/divider_line_bronze.png";
import birdFront from "../../assets/images/blue_background/blue_bird_1.png";
import birdBack from "../../assets/images/blue_background/blue_bird_2.png";
import walkTheTalk1 from "../../assets/images/previous_projects/walk_the_talk_with_702_1.jpg";
import walkTheTalk2 from "../../assets/images/previous_projects/walk_the_talk_with_702_2.jpg";
import walkTheTalk3 from "../../assets/images/previous_projects/walk_the_talk_with_702_3.jpg";
import walkTheTalk4 from "../../assets/images/previous_projects/walk_the_talk_with_702_4.jpg";
import walkTheTalk5 from "../../assets/images/previous_projects/walk_the_talk_with_702_5.jpg";
import parklife1 from "../../assets/images/previous_projects/parklife_1.jpg";
import parklife2 from "../../assets/images/previous_projects/parklife_2.jpg";
import parklife3 from "../../assets/images/previous_projects/parklife_3.jpg";

import { projectsList } from "../../utils/projects";

export default () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const about = useRef(null);
  const story = useRef(null);
  const skills = useRef(null);
  const projects = useRef(null);
  const student = useRef(null);
  const contact = useRef(null);

  const Email =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const executeScroll = (anchor) => {
    switch (anchor) {
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "story":
        story.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "skills":
        skills.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "projects":
        projects.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "student":
        student.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const goToTop = (_) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onSend = (_) => {
    if (!email || !name || !message) {
      alert("Please enter your Name, Surname, Email Address and a Message");
    } else {
      const args = {
        email: email,
        fullname: name,
        company: company,
        message: message,
      };
    }
  };

  return (
    <div className="home-page">
      <Menu scroll={(e) => executeScroll(e)} />

      {showTopBtn && <div className="back-to-top" onClick={goToTop} />}

      <div className="banner">
        <div className="text-container">
          <h1>The Developer's</h1>
          <h1>Guidebook</h1>
        </div>

        <div className="landscape first" />
        <div className="landscape second" />
        <div className="landscape third" />
        <div className="landscape fourth" />
        <div className="landscape fifth" />
        <div className="landscape sixth" />
        <div className="landscape seventh" />
        <img className="birds-front" src={birdFront} alt="birdFront" />
        <img className="birds-back" src={birdBack} alt="birdBack" />
      </div>

      <div className="break" />

      <div className="about spacing" ref={about}>
        <header className="text-center">
          <h1>Epilogue:</h1>
          <img className="divider" src={divider} alt="divider_line" />
          <h1>About the Developer</h1>
        </header>

        <div className="text-container m-auto">
          <p>
            <span className="old-english">M</span>y name is Pieter Burger, I am
            a Developer who has over five years experience in the Multimedia and
            Development Industry, ranging from Frontend Application Development,
            Website Design and Frontend Overhauls. I have a Bachelor's degree in
            Creative Brand Communications from the IIE's Vega School. I am
            currently working as a Frontend Developer for{" "}
            <a href="https://typedev.io/" target="_blank">
              {"{type}DEV"}
            </a>
            , a multinational software development house who has digital
            experiences spanning industries such as insurance and financial
            technology, telecommunications, medico-legal, supply chain and
            sporting sciences. Through {"{type}DEV"} I have worked on multiple
            projects for various clients around the globe and helped each to
            achieve their development goals.
          </p>

          <p>
            I am a hardworking and enthusiastic individual who loves to learn
            new skills and expand my horizons whenever I get the chance. I also
            have some experience in API integration, NodeJS, MongoDB, SEO, React
            Native, Adobe Photoshop, After Effects, Illustrator, and XD.
          </p>
        </div>
      </div>

      <div className="break" />

      <div className="about spacing" ref={story}>
        <header className="text-center">
          <h1>Chapter 1:</h1>
          <img className="divider" src={divider} alt="divider_line" />
          <h1>The Developer's Journey</h1>
        </header>

        <div className="flex gap">
          <div className="text-container f-1 m-auto">
            <div>
              {/* <h3 style={{ width: "25%" }}>An avid learner</h3> */}

              <p>
                <span className="old-english">I</span>n the year 2015 AD, when
                the world was still young in the mind of the developer, vast and
                ready to be explored. Though he was not a developer yet, not
                even knowing it's meaning, he knew that his future lay before
                him and that he had a choice, a choice that would lead him down
                a path that would change his life forever.
              </p>

              <p>
                He always had a fascination with the schools of motion and
                animation, however, the mists of uncertainty lie heavy in the
                mind of the young developer for he did not know which field
                would help him learn the skills needed to survive and flourish.
              </p>

              <p>
                After some searching he found the perfect place to learn, Vega,
                a school where the developer would learn the ways of Multimedia.
                Although not what he initially anticipated, this institution
                gave him his first taste of the development world and its
                wonderous capabilities.
              </p>

              <p>
                In the three years of his study he was taught the ways of
                animation, motion graphics, web design and development, brand
                marketing, concept and campaign creation, skills such as
                Photoshop, After Effects and Illustrator. He was even given an
                award for one of his campaigns in the prestigious Loeries, an
                expansive awards ceremony that spans Africa and the Middle East.
              </p>

              {/* <h3 style={{ width: "34%" }}>Strenghts and stacks</h3> */}

              <p>
                With degree in hand and grateful for the few years he spent
                studying, the young developer ventured into the world as a
                Multimedia designer. However, after only a short time he found
                this path to be lacking and was left dissatisfied. He started to
                stray from this path and found himself wandering into the vast
                fields of Development, a place where he initially only had a
                small glimpse during his years of study.
              </p>

              <p>
                At first he felt overwhelmed by the sheer vastness that lay
                before him, he found a mentor who helped guide him through and
                train him in the ways of development. The more he learned, the
                more he felt his excitement grow at the prospects that lay
                before him. The developer works tirelessly to improve his
                current skill and learn new abilities, staying up to date with
                the latest technical jargon and improvements for his favourite
                stacks. With each passing year, gaining experience and
                confidence, the developer knew that he has found his path and
                his passion.
              </p>

              {/* <h3 style={{ width: "26%" }}>Short biography</h3> */}

              <span className="spacer"></span>

              {/* <p className="text-right">
                ━ Pieter Burger, Sundas, 29th Morning Star, 2023 years of The
                Second Age
              </p> */}
            </div>
          </div>

          <div className="image-section f-1 text-center m-auto">
            <div className="image-container">
              <img src={portrait} alt="Pieter Burger Portrait" />
            </div>

            {/* <h2 className="fffont">
              <span className="old-english">Pieter Burger</span>
            </h2> */}

            <div>
              <p className="text-center">
                “ I am a committed, hardworking, enthusiastic and friendly
                individual who enjoys learning new skills. If presented with a
                task I am unsure of or have little knowledge on, I will
                research, enquire and ensure to complete the project to the best
                of my abilities. ”
              </p>

              <p className="text-right no-padding">
                ━ Pieter Burger, year 23 of the second millennium
              </p>
            </div>
            {/* <p>12/01/1996</p> */}
          </div>
        </div>
      </div>

      <div className="break" />

      <div className="about spacing" ref={skills}>
        <header className="text-center">
          <h1>Chapter 2:</h1>
          <img className="divider" src={divider} alt="divider_line" />
          <h1>My Skills</h1>
        </header>

        <h2 className="text-center padding-bottom">Languages</h2>

        <div className="flex gap padding-bottom">
          <div className="f-1">
            <h3>Afrikaans</h3>

            <Xpbar xp={"85%"} color={"red"} />
          </div>

          <div className="f-1">
            <h3>English</h3>

            <Xpbar xp={"85%"} color={"red"} />
          </div>
        </div>

        <div className="flex gap padding-bottom">
          <div className="f-1">
            <h3>Dutch</h3>

            <Xpbar xp={"20%"} color={"red"} />
          </div>

          <div className="f-1"></div>
        </div>

        <h2 className="text-center padding-bottom">Development</h2>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>HTML 5</h3>

            <Xpbar xp={"85%"} />

            <p>
              I have over seven years of experience in HTML, this is the first
              language that I learned and I am quite confident in my abilities.
              I can easily create an amazing frontend for websites or webapps.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>CSS 3</h3>

            <Xpbar xp={"85%"} />

            <p>
              I have over seven years of experience in CSS and SCSS. I am quite
              confident in my abilities and can easily create an stylish
              frontend for websites or webapps.
            </p>
          </div>
        </div>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>ReactJS</h3>

            <Xpbar xp={"75%"} />

            <p>
              I have over five years of experience in ReactJS. This is my main
              framework and the one I have the most confidence in when it comes
              to frontend development. I have worked with multiple clients
              across the globe to create websites and webapps.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>JavaScript</h3>

            <Xpbar xp={"65%"} />

            <p>
              I have over five years of experience in JavaScript and have used
              it mainly in conjunction with frontend development.
            </p>
          </div>
        </div>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>GitHub</h3>

            <Xpbar xp={"65%"} />

            <p>
              I have over than three years of experience with GitHub. I have
              used it in both my work and personal life for version control,
              branch management and repositories.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>React Native</h3>

            <Xpbar xp={"60%"} />

            <p>
              I have over three years of experience in React Native. I have used
              this framework to create a few mobile applications for the
              transport industry.
            </p>
          </div>
        </div>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>MapBox</h3>

            <Xpbar xp={"50%"} />

            <p>
              I have a few months of experience in MapBox and have created
              interactive maps using GeoJSON to create quadrants on a map, map
              navigation and filtering.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>SEO</h3>

            <Xpbar xp={"45%"} />

            <p>
              I have over three years of experience with SEO. I have helped
              multiple clients to improve their Browser Ranking.
            </p>
          </div>
        </div>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>NodeJS</h3>

            <Xpbar xp={"30%"} />

            <p>
              I have over one year of experience in NodeJS and have used it for
              creating APIs that link to MongoDB with basic CRUD functionality.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>MongoDB</h3>

            <Xpbar xp={"25%"} />

            <p>
              I have less than one year of experience in MongoDB and I have a
              basic understanding of it and how to link to it with NodeJS.
            </p>
          </div>
        </div>

        <div className="flex gap padding-bottom">
          <div className="f-1 m-auto">
            <h3>Framer Motion</h3>

            <Xpbar xp={"20%"} />

            <p>
              I have more than few months of experience in Framer Motion. I have
              used it extensively to create intricate animations for React
              websites and webapps.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>.Net Maui</h3>

            <Xpbar xp={"15%"} />

            <p>
              I have a few months of experience in .Net Maui and .Net Maui with
              blazor. I have some practical experience with creating screens for
              different pages, rendering and API integration.
            </p>
          </div>
        </div>

        <h2 className="text-center padding-bottom">Design</h2>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>Adobe After Effect</h3>

            <Xpbar xp={"45%"} color={"silver"} />

            <p>
              I have more than two years of experience in After Effects. I have
              used it for animation, motion graphics and creation of effects.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>Adobe Premiere Pro</h3>

            <Xpbar xp={"35%"} color={"silver"} />

            <p>
              I have more than a year of experience in Premiere Pro. I have used
              it only for video editing during my student years.
            </p>
          </div>
        </div>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>Adobe inDesign</h3>

            <Xpbar xp={"25%"} color={"silver"} />

            <p>
              I have less than a year of experience with InDesign. I have used
              it mainly for the design of fliers, pamphlets and booklets.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>Adobe Photoshop</h3>

            <Xpbar xp={"55%"} color={"silver"} />

            <p>
              I have more than three years experience with Photoshop. I have
              used it for the creation of Ads, pamphlets, cutting up images and
              image touch-ups.
            </p>
          </div>
        </div>

        <div className="flex gap">
          <div className="f-1 m-auto">
            <h3>Adobe Illustrator</h3>

            <Xpbar xp={"45%"} color={"silver"} />

            <p>
              I have more than three years of experience with Illustrator. I
              have used it to create icons, illustrations, backgrounds, pixel
              art/ characters and SVG images.
            </p>
          </div>

          <div className="f-1 m-auto">
            <h3>Adobe XD</h3>

            <Xpbar xp={"65%"} color={"silver"} />

            <p>
              I have more than two years experience with Adobe XD. I have used
              this to create multiple UI mock-ups, user journeys and rapid
              ideation.
            </p>
          </div>
        </div>
      </div>

      <div className="break" />

      <div className="about spacing" ref={projects}>
        <header className="text-center">
          <h1>Chapter 3:</h1>
          <img className="divider" src={divider} alt="divider_line" />
          <h1>Projects I have worked on</h1>
        </header>

        <div className="m-auto project-container">
          {projectsList?.map((project) => {
            return <Project key={project?.name} data={project} />;
          })}
        </div>
      </div>

      <div className="about student spacing" ref={student}>
        <header className="text-center">
          <h1>Chapter 4:</h1>
          <img className="divider" src={divider} alt="divider_line" />
          <h1>The Student Years</h1>
        </header>

        <div className="m-auto flex padding-bottom">
          <div className="f-1">
            <h4>Third year Project</h4>
            <h2>Name: Language, why not local?</h2>
            <h3>Entered & Won Bronze Loerie in 2017</h3>

            <p>
              For this project I could choose any brand and create a campaign
              for them, I chose Duolingo as my brand.
            </p>
            <p>
              I found that many South Africans would rather want to learn a
              foreign language than local one. This project aims to change that
              by showing South Africans the benefits of learning a local
              language through using Duolingo, who are bringing out a Zulu
              language course.
            </p>
            <p>
              Many South Africans also know at least one word in Zulu, that word
              being “Yebo”, By using two online animated videos, designed in the
              style of Duolingo, we show that knowing this one word can get you
              in, but learning the language will make you part of the group.
            </p>
          </div>

          <div className="f-1 m-auto justify-center flex">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oX8i6yDiU4c"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>

        <div className="m-auto flex padding-bottom">
          <div className="f-1 m-auto">
            <div className="f-1">
              <h4>Third year Project</h4>
              <h2>Name: Know-How Board Game</h2>
              <h3>Concept: Everything is connected</h3>

              <p>
                The challenge was to create a party game for young adults that
                does not include alcohol. The game had to be innovative,
                exciting and bring people together. The game had to be a
                physical thing, offline and app-free, like a card or board game.
              </p>
              <p>
                Through creating a game in which people have to link topics with
                the topics of their colleagues, they can see that, essentially,
                everything is connected. An example of this can be six degrees
                of separation by Kevin Bacon, where he describes that every
                person in the world can be connected to any other person through
                a chain of acquaintances with at least five people in that
                chain.
              </p>
              <p>
                So, in theory, this can also be applied to trivia, where one
                piece of information can lead to another and in essence link two
                different topics. For example, The Kraken, can link to the film
                Pirates of the Caribbean but also to Norse mythology, and also
                link to the game Indiana jones and the Emperors tomb where you
                fight the creature.
              </p>
            </div>
          </div>

          <div className="f-1 m-auto justify-center flex">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/BbA0tTODDCM"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>

        <div className="m-auto flex padding-bottom">
          <div className="f-1 m-auto">
            <div className="f-1">
              <h4>Third year Project</h4>
              <h2>Name: Speak SA Campaign</h2>
              <h3>Concept: Speak SA</h3>

              <p>
                Through using typography to show that the rainbow nation cannot
                work because of each cultures diversity, BUT by embracing our
                unique diversity we can successfully work toward creating a true
                rainbow nation.
              </p>
              <p>
                Through creating a font and kaleidoscope for each culture/
                language made up of everything that makes that culture amazing
                we are in essence celebrating the individuality of each language
                and showing that people should be proud of their language and
                culture.
              </p>
              <p>
                We are in essence promoting each of South Africa's many amazing
                cultures and encouraging people to be proud of their own culture
                and share it with others.
              </p>
            </div>
          </div>

          <div className="f-1 m-auto align-center flex column">
            <iframe
              className="padding-bottom"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/U6T9OLiI5Tg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />

            <iframe
              className="padding-bottom"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/50c5XaDthG0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>

        <div className="m-auto flex">
          <div className="f-1 m-auto">
            <div className="f-1">
              <h4>Third year Project</h4>
              <h2>Name: Gems of the Drained</h2>
              <h3>Task: Create interactive portfolio</h3>

              <p>
                This was my first real challenge when it came to coding. I
                wanted to create an interactive way for people to see the work
                that I have done and the achievements that I have gained. Since
                I was a gamer I thought that a game would be the best way for
                people to experience who I am and how I think.
              </p>
              <p>
                Gems of the Drained is in essence a game that showcase all of my
                best works and projects that I am proud of. With guidance from
                my Lecturer I was able to create this interactive portfolio with
                HTML, CSS and JavaScript.
              </p>
              <p>
                Click the Gem to see and experience the game version. (Desktop
                only)
              </p>
            </div>
          </div>

          <div className="f-1 m-auto align-center flex column gem">
            <a href="https://www.pieterburger.co.za/gamified/" target="_blank">
              <img
                src="https://pieterburger.co.za/wp-content/uploads/2020/01/Float.gif"
                alt="gem"
              />
            </a>
          </div>
        </div>

        <div className="m-auto flex gap">
          <div className="f-1 m-auto">
            <div className="f-1">
              <h4>Third Year Project - College extracurricular activities</h4>
              <h2>Name: MTN Walk the talk with 702</h2>
              <h3>
                Tasked by LeadSA to create an interactive art installation
              </h3>

              <p>
                For walk the talk we were asked by LeadSA to create an
                interactive art installation where people can leave their love
                for South Africa. On the one side, we created a nail wall where
                people can take long pieces of wool and link words together to
                show what made them walk. On the other side, we made a tree
                where people can take sticky paper leaves and leave what they
                love most about South Africa on a tree painted on the wall.
              </p>

              <Carousel>
                <div>
                  <img src={parklife1} />
                </div>
                <div>
                  <img src={parklife2} />
                </div>
                <div>
                  <img src={parklife3} />
                </div>
              </Carousel>
            </div>
          </div>

          <div className="f-1 m-auto">
            <div className="f-1">
              <h4>Third Year Project - College extracurricular activities</h4>
              <h2>Name: Parklife</h2>
              <h3>Tasked by Parklife & Vega to create a fun game</h3>

              <p>
                For Parklife we tasked with creating a game that people can play
                and have fun with, it had to be a safe game that people can't
                get injured with. So therefore, we created giant Jenga, an easy
                game to understand and play, it could be set up again and again
                so people could play all day. I worked with two fellow Vega
                students to create, setup and manage the game at the Parklife
                festival.
              </p>

              <Carousel>
                <div>
                  <img src={walkTheTalk1} />
                </div>
                <div>
                  <img src={walkTheTalk2} />
                </div>
                <div>
                  <img src={walkTheTalk3} />
                </div>
                <div>
                  <img src={walkTheTalk4} />
                </div>
                <div>
                  <img src={walkTheTalk5} />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="banner">
        <div className="landscape forest-first" />
        <div className="landscape forest-second" />
        <div className="landscape forest-third" />
        <div className="landscape forest-fourth" />
        <div className="landscape forest-fifth" />
      </div>

      <div className="contact spacing" ref={contact}>
        <header className="text-center">
          <h1>Prologue:</h1>
          <img className="divider" src={divider} alt="divider_line" />
          <h1>How to contact the Developer</h1>
        </header>

        <div className="form-section">
          <div className="flex gap">
            <div className="f-1">
              <h3 className="text-center">Email: </h3>

              <a href="mailto:pieterbu96@yahoo.com" target="_blank">
                <p className="text-center">pieterbu96@yahoo.com</p>
              </a>
            </div>

            <div className="f-1">
              <h3 className="text-center">Phone: </h3>

              <a href="tel:+27744551511" target="_blank">
                <p className="text-center">+27 74 455 1511</p>
              </a>
            </div>

            <div className="f-1">
              <h3 className="text-center">CV: </h3>

              <a
                href="https://pieterburger.co.za/PieterBurgerCV2023.pdf"
                target="_blank"
              >
                <p className="text-center">Pieter Burger CV 2023</p>
              </a>
            </div>
          </div>

          {/* <div className="flex gap">
            <div className="input-container f-1">
              <h3 className="title">Email</h3>
              <input
                className={!Email.test(email) ? "error" : ""}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-container f-1">
              <h3 className="title">Full Name</h3>
              <input value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="input-container f-1">
              <h3 className="title">Company</h3>
              <input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
          </div>

          <div className="input-container f-1">
            <h3 className="title">Message</h3>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <button onClick={onSend}>Send Message</button> */}
        </div>
      </div>

      <div className="break" />

      {/* <div className="banner">
        <div className="landscape throne-first" />
        <div className="landscape throne-second" />
        <div className="landscape throne-third" />
        <div className="landscape throne-fourth" />
        <div className="landscape throne-fifth" />
        <div className="landscape throne-sixth" />
      </div>

      <div className="break" /> */}
    </div>
  );
};
