import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import xpbar from "../../assets/images/xp_bar_clear.png";
import xpbar from "../../assets/images/xp_bar_clear_filled_bg.png";

import "./_styles.scss";

export default ({ xp, color }) => {
  const xpBarWidth = {
    visible: { width: xp, transition: { duration: 1 } },
    hidden: { width: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="xp-container">
      <img src={xpbar} />

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={xpBarWidth}
        className={
          color == "red"
            ? "xp-bar red"
            : color == "silver"
            ? "xp-bar silver"
            : "xp-bar"
        }
        style={{ width: xp }}
      />
    </div>
  );
};
