import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Pages from "./pages/index";

import "./styles/_global.scss";
import "./assets/font/finalf.ttf";

function App() {
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Pages.Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
