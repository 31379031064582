import React, { useEffect, useState } from "react";

import "./_styles.scss";

export default (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1400) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, []);

  return (
    <nav className={show ? "show" : ""}>
      <div className="nav-container">
        <p onClick={(_) => props.scroll("about")}>About</p>
        <p onClick={(_) => props.scroll("story")}>Story</p>
        <p onClick={(_) => props.scroll("skills")}>Skills</p>
        <p onClick={(_) => props.scroll("projects")}>Projects</p>
        <p onClick={(_) => props.scroll("student")}>Student</p>
        <p onClick={(_) => props.scroll("contact")}>Contact</p>
      </div>
    </nav>
  );
};
