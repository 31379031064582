import React from "react";

import "./_styles.scss";

export default ({ data }) => {
  const {
    name,
    period,
    company,
    jobTitle,
    type,
    teamSize,
    about,
    responsibilities,
    tools,
    url,
    urlName,
  } = data;

  return (
    <div className="project">
      <h1>{name}</h1>

      <div className="flex padding-bottom gap">
        <div className="f-1">
          <h2>Company:</h2>
          <p>{company}</p>
        </div>

        <div className="f-1">
          <h2>Job Title:</h2>
          <p>{jobTitle}</p>
        </div>

        <div className="f-1">
          <h2>Period:</h2>
          <p>{period}</p>
        </div>

        <div className="f-1">
          <h2>Team Size:</h2>
          <p>{teamSize}</p>
        </div>
      </div>

      <div className="padding-bottom">
        <h2>About the Project:</h2>
        <p>{about}</p>
        <a href={url} target="_blank">
          {urlName}
        </a>
      </div>

      <div className="padding-bottom">
        <h2>Responsibilities: </h2>
        <ul>
          {responsibilities?.map((res, i) => {
            return <li key={i}>{res}</li>;
          })}
        </ul>
      </div>

      <h2>Technologies and Tools used: </h2>
      <ul>
        {tools?.map((tool, i) => {
          return <li key={i}>{tool}</li>;
        })}
      </ul>
    </div>
  );
};
